import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { FaBars, FaTimes } from 'react-icons/fa';

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="logo" onClick={closeMobileMenu}>
          <img src="/logo.png" alt="Logo" />
        </Link>

        {/* Hamburger Menu Icon */}
        <div
          className="menu-icon"
          onClick={handleMenuToggle}
          aria-label="Menu"
          aria-expanded={isMobileMenuOpen}
          role="button"
          tabIndex="0"
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleMenuToggle();
            }
          }}
        >
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </div>

        {/* Navigation Links */}
        <ul className={isMobileMenuOpen ? 'nav-links mobile' : 'nav-links'}>
          <li>
            <Link to="/" onClick={closeMobileMenu}>
              Chat
            </Link>
          </li>
          <li>
            <Link to="/about" onClick={closeMobileMenu}>
              About Us
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={closeMobileMenu}>
              Contact Us
            </Link>
          </li>
          <li>
            <Link to="/privacy-policy" onClick={closeMobileMenu}>
              Privacy Policy
            </Link>
          </li>
        </ul>
      </div>

      {/* Overlay */}
      {isMobileMenuOpen && <div className="overlay" onClick={closeMobileMenu}></div>}
    </nav>
  );
}

export default Navbar;