import React from 'react';
import './AboutUs.css';

function AboutUs() {
  return (
    <div className="about-us">
      <h1>About Us</h1>
      <p>
        Welcome to <strong>Graystone Enterprises Inc.</strong>
      </p>
      <p>
        As a licensed landscape contractor recognized by the California State Contractors License Board, we at
        Graystone Enterprises specialize in transforming outdoor spaces with high-quality artificial grass turf.
        Our unique position as both seasoned software engineers and landscape professionals enables us to offer
        innovative solutions that connect California consumers with top-tier artificial turf vendors. Whether you’re
        looking for recycled turf salvaged from athletic fields across the United States or prefer new, locally
        manufactured turf, we have the resources to meet your needs.
      </p>
      <h2>Innovating Landscape Solutions with Technology</h2>
      <p>
        Our dedication to technology and customer service is at the heart of our operations. Utilizing advanced
        Rasa artificial intelligence and machine learning, we precisely match your specific landscape requirements
        with the best licensed, bonded, and insured landscape contractors near you. This service is provided
        absolutely free, streamlining your experience and ensuring quality and reliability.
      </p>
      <h2>How It Works</h2>
      <p>
        Simply chat with our AI bot, and let Rasa’s intelligent algorithms do the rest. Through integration with Ring
        Central’s SMS API, we can send you a text message with details of the nearest facilitator to provide your
        chosen artificial grass, or connect you with three licensed professionals ready to bid on your project. This
        seamless process is designed to save you time and ensure that you receive the best options available.
      </p>
      <h2>Located in Sunny San Diego, CA</h2>
      <p>
        Based in the vibrant city of San Diego, Graystone Enterprises is committed to <em>“Making Finding a Landscape
        Pro Great Again.”</em> We are here to ensure that your journey to a beautiful, sustainable landscape is smooth
        and successful.
      </p>
      <h2>Connect with Us</h2>
      <p>
        Experience the future of landscape contracting through cutting-edge technology and expert service. Connect
        with us today and see how we can help transform your outdoor space into something extraordinary.
      </p>
    </div>
  );
}

export default AboutUs;