import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for internal routing
import './TermsAndConditions.css'; // Ensure you create this CSS file

function TermsAndConditions() {
  return (
    <div className="terms">
      <h1>Terms and Conditions</h1>
      <p>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THE SERVICES PROVIDED BY GRAYSTONE ENTERPRISES INC. (“GRAYSTONE”, “WE”, “US”, OR “OUR”). BY ACCESSING OR USING OUR WEBSITE OR SERVICES, YOU (“USER”, “YOU”, OR “YOUR”) AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS. IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT USE OUR SERVICES.</p>
      <p>LAST UPDATED: Dec. 4th, 2024</p>
      
      <p>IMPORTANT: PLEASE REVIEW THIS AGREEMENT CAREFULLY. IN PARTICULAR, PLEASE REVIEW THE MUTUAL ARBITRATION PROVISION IN SECTION [X].</p>
      
      <h2>1. INTRODUCTION</h2>
      <p>Graystone Enterprises Inc. provides a platform that connects users seeking landscaping services with licensed contractors and facilitators. Our Services are offered to you conditioned upon your acceptance without modification of the following terms, conditions, and notices. By using our website or services, you agree to be bound by these Terms and Conditions in effect at the time of your use.</p>
      
      <h2>2. REGISTRATION AND ACCOUNT SECURITY</h2>
      <p>Upon registration, you may choose your own password or Graystone will assign you a password to access the Services through our website or applications. You are responsible for maintaining the confidentiality of your account information and are fully responsible for all activities that occur under your account.</p>
      
      <h2>3. STANDARD SERVICES</h2>
      <p>Graystone is committed to facilitating a positive experience for both users and service providers. Our Services include:</p>
      <ul>
        <li>Geo-Location Matching: Using your provided ZIP code, we geo-locate the nearest turf facilities and licensed contractors based on their office locations.</li>
        <li>Contractor Selection: We randomly select up to three licensed contractors in good standing with the California Contractors State License Board (CSLB) to match your landscaping project needs.</li>
        <li>Information Transmission: Utilizing the RingCentral API, we securely send your project information to the selected contractors and facilitators, including your ZIP code, name, contact information, and project details.</li>
        <li>Interactive Project Assessment: Powered by Rasa AI, we engage with you to gather detailed information about your landscaping project, ensuring that matched contractors are licensed in the relevant trade and active with the CSLB.</li>
      </ul>
      
      <h2>4. NO GUARANTEES OR ENDORSEMENTS</h2>
      <p>While we strive to provide accurate and reliable matches, Graystone does not guarantee the availability, capabilities, or quality of any contracted service providers. All service providers are independent entities, and Graystone is not a party to any agreements between users and service providers.</p>
      
      <h2>5. NO CONTRACTING VIA THE GRAYSTONE PLATFORM</h2>
      <p>Any agreements or contracts for services must be made directly between the user and the service provider. Graystone does not facilitate, endorse, or enter into any contractual agreements on behalf of either party.</p>
      
      <h2>6. RELEASE FROM DAMAGES OR CLAIMS</h2>
      <p>You agree to release Graystone, including its officers, directors, shareholders, affiliates, employees, and agents, from any and all damages or claims arising out of your use of the Services or your dealings with service providers.</p>
      
      <h2>7. YOUR USE OF THE SERVICES AND PROHIBITED USES</h2>
      <p>You agree to use Graystone solely for personal, non-commercial purposes. Prohibited activities include, but are not limited to:</p>
      <ul>
        <li>Unauthorized access or use of the Services.</li>
        <li>Interfering with the functionality of the Services.</li>
        <li>Using automated systems to access or interact with the Services.</li>
        <li>Misrepresenting your identity or intentions.</li>
      </ul>
      <p>Violation of these terms may result in termination of your access to the Services and potential legal action.</p>
      
      <h2>8. GENIE PRO GOLD MEMBERSHIP (IF APPLICABLE)</h2>
      <p>Graystone may offer membership programs that provide discounts or special offers on turf products or services. Membership terms, billing, automatic renewal, cancellation policies, and other relevant details will be provided at the time of purchase.</p>
      
      <h2>9. OFFERS AND DISCOUNTS</h2>
      <p>Referral Discounts: Users may receive referral codes to share with friends and acquaintances. These codes are for personal use only and cannot be distributed through public marketing channels.</p>
      <p>Promotional Coupons: Promotional coupons are subject to specific terms, including validity periods and applicable services. Coupons have no cash value and cannot be combined with other offers unless explicitly stated.</p>
      
      <h2>10. INFORMATION YOU PROVIDE TO US</h2>
      <p>By using Graystone, you consent to the collection, storage, and use of your personal information as described in our <Link to="/privacy-policy">Privacy Policy</Link>.</p>
      
      <h2>11. GRAYSTONE MESSAGING</h2>
      <p>You may receive automated promotional and personalized marketing messages from Graystone. Consent to receive such messages is not a condition of any purchase. Message frequency and content may vary, and you can opt-out at any time by following the instructions provided in the messages.</p>
      
      <h2>12. DISPUTE RESOLUTION ASSISTANCE</h2>
      <p>Graystone provides a resolution process to assist in resolving disputes between users and service providers. However, Graystone is not responsible for the performance or conduct of any service providers.</p>
      
      <h2>13. USER GENERATED CONTENT</h2>
      <p>All content you post on Graystone, including photographs, comments, and reviews, remains your property. However, by posting content, you grant Graystone a perpetual, worldwide, royalty-free license to use, reproduce, and distribute your content in connection with providing the Services.</p>
      
      <h2>14. SERVICE PROVIDER SCREENING</h2>
      <p>Graystone utilizes APIs from RingCentral and the California Contractors State License Board (CSLB) to verify the licensing and standing of contractors. While we take steps to ensure the accuracy of this information, we do not guarantee the ongoing compliance or status of any service provider.</p>
      
      <h2>15. DISCLAIMER OF WARRANTIES</h2>
      <p>Graystone provides the Services “AS IS” without any warranties, express or implied. We disclaim all warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
      
      <h2>16. LIMITATION OF LIABILITY</h2>
      <p>Graystone shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of your use of the Services. Our total liability shall not exceed the amount you have paid to Graystone, if any.</p>
      
      <h2>17. INDEMNIFICATION</h2>
      <p>You agree to indemnify and hold harmless Graystone, its affiliates, officers, directors, employees, and agents from any claims, damages, liabilities, and expenses arising out of your use of the Services or violation of these Terms and Conditions.</p>
      
      <h2>18. AGREEMENT TO ARBITRATE AND GOVERNING LAW</h2>
      <p>Any disputes arising out of or related to these Terms and Conditions or the Services shall be resolved through binding arbitration administered by the American Arbitration Association. This agreement to arbitrate does not preclude you from seeking provisional remedies in court for certain issues. These Terms and Conditions shall be governed by the laws of the State of [Your State], without regard to its conflict of law principles.</p>
      
      <h2>19. GENERAL PROVISIONS</h2>
      <ul>
        <li>Entire Agreement: These Terms and Conditions constitute the entire agreement between you and Graystone regarding the use of the Services.</li>
        <li>Severability: If any provision of these Terms is found to be unenforceable, the remaining provisions shall remain in full force and effect.</li>
        <li>Waiver: Failure to enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.</li>
        <li>Modification: Graystone reserves the right to modify these Terms and Conditions at any time by posting a revised version on our website. Continued use of the Services constitutes acceptance of the updated Terms.</li>
      </ul>
      
      <h2>20. CONTACT INFORMATION</h2>
      <p>If you have any questions about these Terms and Conditions, please contact us at:</p>
      <address>
        Graystone Enterprises Inc.<br />
        1590 Continental St. Suite 206<br />
        San Diego, California, 92154<br />
        Email: <a href="mailto:GraystoneEnterInc@icloud.com">GraystoneEnterInc@icloud.com</a><br />
        Phone: (844) 444-0469<br />
        Website: <a href="http://www.LandscapeGenie.com" target="_blank" rel="noopener noreferrer">www.LandscapeGenie.com</a>
      </address>
    </div>
  );
}

export default TermsAndConditions;