import React from 'react';
import { Link } from 'react-router-dom'; // This assumes you are using react-router for navigation
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <p>
        &copy; {new Date().getFullYear()} Graystone Enterprises Inc. All rights reserved. 
        <Link to="/terms-and-conditions" className="footer-link"> Terms and Conditions</Link> | 
        <Link to="/privacy-policy" className="footer-link"> Privacy Policy</Link>
      </p>
    </footer>
  );
}

export default Footer;