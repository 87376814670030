import React, { useState } from 'react';
import './ContactUs.css';

function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',  // Added phone state
    message: '',
    smsConsent: false, // Added SMS consent state
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Your message has been sent! We’ll get back to you soon.');
    setFormData({
      name: '',
      email: '',
      phone: '',
      message: '',
      smsConsent: false,
    });
  };

  return (
    <div className="contact-us">
      <h1>Contact Us</h1>
      <div className="company-info">
        <p><strong>Graystone Enterprises Inc.</strong></p>
        <p>Email: <a href="mailto:graystoneenterinc@icloud.com">graystoneenterinc@icloud.com</a></p>
        <p>Customer Service: (844) 444-0469</p>
        <p>California Office: (858) 926-5331 (SMS Friendly)</p>
        <p>Fax: (858) 926-5331</p>
        <p>Texas Office: (915) 455-4080 (SMS Friendly)</p>
        <p>Mexico: +52 33 2754 1028 (WhatsApp Available)</p>
        <p>Office: 1590 Continental St Suite 206, San Diego, CA 92154, United States</p>
      </div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Your Name</label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <label htmlFor="email">Your Email</label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <label htmlFor="phone">Phone Number</label>
        <input
          type="tel"
          id="phone"
          name="phone"
          placeholder="Your Phone Number"
          value={formData.phone}
          onChange={handleChange}
          required
        />
        <div className="checkbox-container">
          <input
            type="checkbox"
            id="smsConsent"
            name="smsConsent"
            checked={formData.smsConsent}
            onChange={handleChange}
          />
          <label htmlFor="smsConsent">
            By checking this box, you agree to receive SMS text messages from Graystone Enterprises Inc. Reply STOP to opt out at any time. Reply HELP for customer care contact information. Messages and data rates may apply. Message frequency will vary.
          </label>
        </div>
        <label htmlFor="message">Your Message</label>
        <textarea
          id="message"
          name="message"
          placeholder="Write your message here..."
          rows="6"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
        <button type="submit">Send Message</button>
      </form>
    </div>
  );
}

export default ContactUs;